body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blogletter-card {
  min-height: 100%;
}

.blogletter-card-highlighted > .card-body {
  background-color: #e6d1784f;
}

.blogletter-card > .card-body {
  display: flex;
  flex-flow: column;
}

.blogletter-card > .card-body > .card-text {
  flex-grow: 1;
}

.content-holder {
  flex-grow: 1;
}

.category-image {
  width: 64px;
  height: 64px;
}

.category-image-small {
  width: 2em;
  height: 2em;
}

.terms table,
th,
td {
  border: 1px solid black;
  padding: 0.2em;
}
